@import '~@travel-ui/styles/src/utility';

.contentBox {
  padding: $spacing-08 $spacing-16;
  background: $white;

  @include media(pc) {
    background: transparent;
    padding: $spacing-08 0;
  }
}

.modificationBox {
  display: flex;
  margin-top: $spacing-08;

  @include media(pc) {
    margin-top: $spacing-04;
  }

  .rtxImage {
    width: 150px;
    object-fit: contain;
  }

  .link {
    @include font-sp-body-b2;
    color: $dark-cyan;
    display: inline-block;
    margin-left: $spacing-08;
    text-decoration: underline;

    @include media(pc) {
      @include font-pc-body-b2;
    }
  }
}

.navBox {
  margin-top: $spacing-08;
  @include media(pc) {
    display: inline-flex;
    flex-direction: column;
  }

  .navLink {
    display: flex;
    width: 100%;
    background: $white;
    align-items: center;
    color: $jet;
    padding: $spacing-08 $spacing-16;

    @include media(sp, tl) {
      border-bottom: 1px solid $isabelline;
      justify-content: space-between;
    }

    @include media(pc) {
      padding: $spacing-04 0;
      color: $dark-cyan;
    }
  }
}

.navLink,
.link {
  &:hover {
    color: $deep-blue;
  }
}
