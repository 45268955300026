@import '~@travel-ui/styles/src/utility';

.button {
  margin: auto;
  margin-bottom: $spacing-08;
  width: 100%;
  min-height: 36px;
  padding: $spacing-04;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media(pc, tl) {
    margin: 0;
    margin-right: $spacing-08;
    padding: 0;
    min-width: 320px;
    max-height: 40px;
  }
}

.icon {
  margin-right: $spacing-04;
  min-width: 16px;
}

.notice {
  @include font-sp-body-b2;
  color: $sonic-silver;
}

.applied {
  display: flex;
}
