@import '~@travel-ui/styles/src/utility';

@mixin fontJourneyLeg {
  @include font-sp-body-b2;

  @include media(pc, tl) {
    @include font-pc-body-b2;
  }
}

.header {
  @include font-sp-captions-c2-bold;
  color: $sonic-silver;
  /* identical to box height, or 160% */
  letter-spacing: 0.12px;

  &.reserveHeight {
    min-height: 14px;
  }
}

.date {
  @include fontJourneyLeg();
  font-weight: 600;
}

.time {
  @include fontJourneyLeg();
  font-weight: 300;
}

.inlineDateTime {
  display: flex;

  @include media(tl, pc) {
    flex-direction: column;
  }

  .growDiv {
    flex-grow: 1;
  }
}

.container {
  @include font-sp-body-b2;

  @include media(tl, pc) {
    @include font-pc-body-b2;
  }
}
