@import '~@travel-ui/styles/src/utility';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: $spacing-16;
  width: 100%;
  align-items: center;
  font-weight: bold;
  background-color: $white;
  z-index: $z-index-header;
  @include box-shadow-middle;

  & > .icon {
    @include resetButtonStyle;
    margin-right: $spacing-08;
  }
}
