@import '~@travel-ui/styles/src/utility';

.loading {
  text-align: center;
  margin-top: $spacing-24;
  margin-bottom: $spacing-16;

  @include media(pc) {
    margin-top: $spacing-32;
  }
}
