@import '~@travel-ui/styles/src/utility';

.link {
  color: $dark-cyan;
  text-decoration: underline;

  &:hover {
    color: $deep-blue;
  }
}

.providerName {
  @include font-sp-body-b2-bold;

  @include media(pc) {
    @include font-pc-body-b2-bold;
  }
}

.isColumn {
  flex-direction: column;
}

.isLast {
  padding-bottom: $spacing-16;
}

.secondaryLang {
  @include font-sp-body-b2;
  color: $sonic-silver;

  @include media(pc) {
    @include font-pc-body-b2;
  }
}

.multipleProvider {
  font-size: 10px;
}

.topPush {
  margin-top: $spacing-16;
}

.providerNameHeader {
  @include font-sp-captions-c2;
}

.labelSpace {
  margin-right: $spacing-04;
}
