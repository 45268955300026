@import '~@travel-ui/styles/src/utility';

.dialog {
  &#{&} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zIndex-modal;
    background-color: $isabelline;
    max-height: 100%;

    @include media(sp, tl) {
      @include full-vh-box;
      width: 100%;
    }

    @include media(pc) {
      position: relative;
      z-index: $zIndex-modal;
      width: 375px;
      height: max-content;

      .content {
        padding: $spacing-16;
        min-height: 400px;
      }
    }
    .content {
      padding: 52px $spacing-16 $spacing-16;
      width: 100%;

      &.fullWidth {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
