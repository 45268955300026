@import '~@travel-ui/styles/src/utility';

.container {
  &#{&} {
    @include font-sp-body-b2;
    padding: $spacing-08 $spacing-12;
    margin: 0;

    &:first-child {
      margin-top: $spacing-08;
      @include media(pc, tl) {
        margin-top: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: $spacing-08;

      @include media(pc, tl) {
        margin-bottom: $spacing-16;
      }
    }
  }
}

.icon {
  &#{&} {
    align-items: center;
  }
}
