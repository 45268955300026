@import '~@travel-ui/styles/src/utility';

$menu-tab-border: 1px solid $isabelline;

.tabMenu {
  background: $white;
  display: flex;
  overflow: auto;

  @include media(sp) {
    position: sticky;
    top: 0;
    z-index: $zIndex-overlay3;
    border-bottom: $menu-tab-border;
    border-top: $menu-tab-border;
  }

  @include media(pc) {
    :last-child {
      margin-left: auto;
    }
  }
}

.tabButton {
  flex-shrink: 0;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 0;

  @include media(pc) {
    flex-grow: 0;
  }
}

.tabInnerButton {
  @include font-sp-body-b1-bold;
  width: 100%;
  color: $sonic-silver;
  border-bottom: 2px solid transparent;

  @include media(pc) {
    padding-left: $spacing-24;
    padding-right: $spacing-24;
  }
}

.selectedTabInnerButton {
  color: $cilantro;
  border-bottom: 2px solid $cilantro;
}

.oldPageRedirection {
  display: flex;
  align-items: center;
  color: $dark-cyan;

  & > .oldPageRedirectionIcon {
    margin-left: $spacing-08;
  }

  &:hover {
    color: $deep-blue;
  }
}
