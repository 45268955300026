@import '~@travel-ui/styles/src/utility';

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: $spacing-08 0;
  width: 100%;
  background: $white;
  border: 1px solid $pastel-gray;
  border-radius: 3px;
  flex-wrap: wrap;
  height: 300px;

  &:first-child {
    margin-top: 0;
  }

  @include media(pc, tl) {
    flex-direction: row;
    height: 200px;
    margin: $spacing-16 0;
  }
}

.actionContainer {
  display: inline-flex;
  border-top: 1px solid $isabelline;
  padding: $spacing-16;
  width: 100%;
  order: 3;
  margin-top: auto;
  height: 86px;

  @include media(pc) {
    margin: $spacing-16 $spacing-16 $spacing-16 auto;
    flex: 0 1 248px;
    order: 2;
    border-top: none;
    border-radius: 4px;
    padding: 0;
    flex-direction: column;
    height: 100%;
  }
}

.action {
  width: calc(100% / 3);
  flex-direction: column;
  height: 90%;
  &:not(:last-child) {
    border-right: 1px solid $isabelline;
  }

  @include media(pc) {
    box-sizing: border-box;

    width: 248px;
    height: 36px;

    border: 1px solid $isabelline;
    border-radius: 4px;
    margin-bottom: $spacing-08;
  }
}

.reservationMedia {
  width: 96px;
  height: 54px;
  border-radius: 4px;

  @include media(pc, tl) {
    display: flex;
    width: 152px;
    margin: $spacing-16;
    border-radius: 4px;
  }
}

.skeleton {
  background-color: $isabelline;
  position: relative;
  overflow: hidden;
  width: 96px;
  height: 54px;
  order: 2;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba($white, 0.4), transparent);
    position: absolute;
    top: 0;
    left: 0;
    animation: skeleton-animation 1.2s linear infinite;
  }

  @include media(pc, tl) {
    order: 1;
    width: 152px;
    height: 95px;
  }
}

.tagContainer {
  margin: $spacing-16 $spacing-16 $spacing-08 $spacing-16;
  order: 1;

  @include media(pc, tl) {
    order: 2;
    margin: $spacing-16;
  }
}

.tagSpacing {
  margin-right: $spacing-04;
}

.mediaContainer {
  order: 2;
  margin-left: auto;
  margin-right: $spacing-16;
  @include media(pc, tl) {
    order: 1;
    margin: unset;
  }
}
