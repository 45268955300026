@import '~@travel-ui/styles/src/utility';

$actions: 1, 2, 3, 4, 5;

.container {
  display: inline-flex;
  border-top: 1px solid $isabelline;
  padding: $spacing-08 0;
  width: 100%;

  @include media(pc) {
    border-top: none;
    @include border-radius-all($border-radius-4px);
    padding: 0;
    flex-direction: column;
  }
}

.iconContainer {
  justify-content: center;
  display: flex;

  @include media(pc) {
    display: none;

    &.showIcon {
      display: flex;
      padding: $spacing-08 $spacing-04;
    }
  }
}

@each $action in $actions {
  .action-#{$action} {
    width: calc(100% / $action);
    flex-direction: column;
    padding: 0 $spacing-04;

    &:not(:last-child) {
      border-right: 1px solid $isabelline;

      @include media(pc) {
        border-right-color: $cilantro;
      }
    }

    @include media(pc) {
      border: 1px solid $cilantro;
      @include border-radius-all($border-radius-4px);
      width: 100%;
      margin-bottom: $spacing-08;
      flex-direction: row;
      display: inline-flex;
      justify-content: center;

      &:hover {
        border: 1px solid $lincoln-green;

        .actionText {
          color: $lincoln-green;
        }
      }
    }
  }
}

.actionText {
  @include font-pc-captions-c2;
  color: $jet;
  text-align: center;
  padding-top: $spacing-08;

  @include media(pc, tl) {
    @include font-pc-body-b2;
    text-align: center;
    padding-bottom: $spacing-08;
  }

  @include media(pc) {
    @include bold;
    color: $cilantro;
  }
}

.detail {
  @include border-radius-all(4px);
  background: $cilantro;

  .actionText {
    color: $white;
  }

  &:hover {
    background: $lincoln-green;

    .actionText {
      color: $white;
    }
  }
}

.jrDetailButton {
  &#{&} {
    @include border-radius-all(4px);
    max-width: unset;
    min-height: 36px;
    width: 100%;
  }
}

.otherButton {
  @include resetButtonStyle;
  padding: 0 4px;
  position: relative;
  flex-direction: inherit;

  .balloon {
    width: fit-content;
    height: initial;
    border: 1px solid $white;
    display: flex;
    flex-direction: column;
    padding: 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 2px 3px);
    box-shadow: none;
    position: absolute;
    z-index: 2;
    right: 8px;

    @include media(sp, tl) {
      bottom: 100%;
    }

    .actionLink {
      padding: $spacing-08;
      border-bottom: 1px solid $isabelline;
      text-decoration: none;
      text-align: left;
      color: $jet;
      @include font-pc-body-b2;

      span {
        white-space: nowrap;
      }
    }

    .actionLink:last-child {
      border-bottom: none;
    }

    .triangle {
      border: none;
      width: 16px;
      height: 16px;
      z-index: -1;
      right: calc((100vw / 8) - 21px);
    }
  }
}
