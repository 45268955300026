@import '~@travel-ui/styles/src/utility';

.container {
  display: flex;
  flex-direction: column;
  padding: $spacing-16;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: $spacing-16;
}

.dialogWrapper {
  @include media(pc, tl) {
    width: 75vh;
  }
}

.containerDialog {
  @include border-radius-all($border-radius-4px);
  min-height: 130px;
}
