@import '~@travel-ui/styles/src/utility';

.closeButton {
  @include resetButtonStyle;
  cursor: pointer;
  position: absolute;
  top: $spacing-m;
  right: $spacing-m;
}

.wrapperModal {
  &#{&} {
    height: 100%;
    width: 100vw;

    @include media(sp, tl) {
      // this is always a fullscreen dialog.
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}

.wrapperDialog {
  background: $white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  padding-top: 56px;

  @include media(pc) {
    max-width: 800px;
  }

  @include media(sp) {
    border-radius: 3px;
    padding-top: $spacing-m + $spacing-l;
  }
}

.contentWrapper {
  width: 100%;
}

.containerDialog {
  @include media(tl, pc) {
    &#{&} {
      max-height: 75vh;
    }
  }
  overflow: auto;
  padding-bottom: $spacing-xl;

  &.resetPaddingBottom {
    padding-bottom: 0;
  }
}

.container {
  background: $white;
  height: 100%;
  width: 100%;
  min-height: 0;
}

.wrapper {
  @include media(sp, tl) {
    @include full-vh-box;
  }
}
