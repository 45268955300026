@import '~@travel-ui/styles/src/utility';

.JrIcon {
  margin-right: $spacing-04;
}

.jRDpExtraText {
  @include font-pc-price-p8;
  text-align: center;
  margin: $spacing-04 auto 0;
  display: block;
}

.invisibleSubmitButton {
  @include resetButtonStyle;
  margin-left: auto;
  display: block;
}

.jRdPReservationDetailLabel {
  margin-left: $spacing-04;
}
