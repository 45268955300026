@import '~@travel-ui/styles/src/utility';

.container {
  width: 90%;
  height: 294px;
  background-color: $white;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin-top: $spacing-16;
}

.dialogContent {
  background-color: $isabelline;
  height: 100%;
  overflow: hidden;
}

.dialogContainer {
  @include media(pc) {
    &#{&} {
      width: 450px;
      height: 400px;
    }
  }
}

.reservationId {
  @include font-sp-header-h3;
  margin-top: $spacing-16;
}

.qrCode {
  width: 160px;
  height: 160px;
}
