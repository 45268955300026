@import '~@travel-ui/styles/src/utility';

.container {
  margin-bottom: $spacing-08;
  width: 100%;

  @include media(pc, tl) {
    margin-bottom: $spacing-16;
  }
}

.headerMedia {
  width: 96px;
  border-radius: 4px;
  max-height: 54px;
  background: transparent;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
}

.reservationTitleRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reservationTitleBox {
  margin-right: $spacing-08;

  @include media(pc, tl) {
    margin-right: 0;
  }
}

.title {
  @include font-pc-body-b2;

  @include media(pc, tl) {
    @include font-pc-header-h5;
  }
}

.secondaryTitle {
  @include font-sp-body-b2;
  color: $sonic-silver;
  font-weight: 300;

  @include media(tl, pc) {
    @include font-pc-body-b2;
  }
}

.link {
  color: $dark-cyan;
}
