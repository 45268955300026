@import '~@travel-ui/styles/src/utility';

.filterContainer {
  display: flex;
  white-space: nowrap;
  overflow: auto;
  margin: 0 -#{$spacing-16};
  padding-left: $spacing-16;
  padding-bottom: $spacing-08;

  &::-webkit-scrollbar {
    display: none;
  }

  &.periodOpened {
    overflow: visible;
  }

  @include media(pc) {
    padding-bottom: $spacing-16;
  }
}

.filterButton {
  &#{&} {
    border: 1px solid $pastel-gray;
    background-color: $white;
    border-radius: 28px;
    min-width: unset;
    min-height: unset;
    padding: $spacing-08 $spacing-12;
    font-weight: normal;
    margin-right: $spacing-08;

    &.active {
      border: 1px solid $cilantro;
      background-color: $laurel-green;
      color: $cilantro;
    }
  }
}

.selectBox {
  display: flex;
  align-items: center;

  // to not display check mark icon on displayed box
  > span > svg {
    display: none;
  }
}

.popupWrapper {
  left: 0;
  margin-top: $spacing-08;
  width: 150px;

  @include media(pc) {
    margin-top: $spacing-16;
  }

  .optionItemIcon {
    display: inline-block;
    margin-right: $spacing-08;
    visibility: hidden;
  }

  .optionItem {
    padding-left: $spacing-16;
    padding-right: $spacing-16;

    &:not(:last-child) {
      border-bottom: 1px solid $shady-white;
    }
  }

  .selectedOptionItem .optionItemIcon {
    visibility: visible;
  }
}
