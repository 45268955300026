@import '~@travel-ui/styles/src/utility';

.coupon {
  border-bottom: 1px solid $isabelline;
  padding: $spacing-16;
}

.default {
  background-color: $white;
}

.selected {
  background-color: $laurel-green;
}

.warning {
  @include font-pc-captions-c1;
  background-color: $mellow-yellow;
  color: $chocolate;
}

.bold {
  font-weight: bold;
}

.couponInfo {
  @include font-pc-price-p8;
}

.couponValidityDate {
  @include font-pc-captions-c2;
}

.mediumBottomPush {
  padding-bottom: $spacing-08;
}

.smallBottomPush {
  margin-bottom: $spacing-04;
}

.highlightedText {
  color: $scarlet;
}

.couponPriceWarning {
  @include font-sp-body-b2;
}

.warningContainer {
  display: flex;
  align-items: center;
}

.noticeUserContainer {
  margin-right: $spacing-08;
}

.removedCouponContainer {
  background-color: $white;
  padding: $spacing-08;
  display: flex;
  color: $black;
  justify-content: space-between;
  margin-top: $spacing-08;
  position: relative;
}
.couponContainer {
  display: flex;
}

.pushRightSmall {
  margin-right: $spacing-04;
}

.crossLine {
  position: absolute;
  width: 95%;
  border-color: $jet;
  border-bottom: 0;
  margin: 0;
  top: 50%;
}

.highlightPrice {
  @include font-pc-body-b2;
  font-weight: bold;
}

.truncated {
  @include text-truncate(1);
}

.couponLabel {
  flex: 1;
}

.removedCouponIcon {
  @extend .pushRightSmall;
  flex-shrink: 0;
}

.removedCouponPrice {
  @extend .bold;
  white-space: nowrap;
}
