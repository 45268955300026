@import '~@travel-ui/styles/src/utility';

.tagContainer {
  display: flex;
  padding-bottom: $spacing-08;
}
.rightPush {
  margin-right: $spacing-04;
}

.tagIcon {
  margin-right: $spacing-04;
  flex-shrink: 0;
}

.tagTitle {
  @include font-sp-captions-c2;

  &.typeTag {
    color: $sonic-silver;
  }
}
