@import '~@travel-ui/styles/src/utility';

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: $spacing-08 0;
  width: 100%;
  background: $white;
  border: 1px solid $pastel-gray;
  border-radius: 3px;
  flex-wrap: wrap;

  &:first-child {
    margin-top: 0;
  }

  @include media(pc, tl) {
    flex-direction: row;
    margin: $spacing-16 0;
  }
}

.detailLink {
  &#{&} {
    margin: $spacing-08 0 0 auto;
    display: flex;

    @include media(tl, pc) {
      margin: $spacing-16 auto 0 0;
    }
  }
}

.contentContainer {
  width: 100%;
  padding: $spacing-16;
  padding: $spacing-16 $spacing-16 0 $spacing-16;
  margin-bottom: $spacing-08;
  order: 1;

  @include media(pc, tl) {
    flex: 1;
    max-width: 552px;
    margin-bottom: $spacing-16;
  }
}

.actionContainer {
  width: 100%;
  order: 3;

  @include media(pc) {
    margin: $spacing-16;
    max-width: 248px;
    margin-left: auto;
    order: 2;
  }
}

.notificationSection {
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-16 $spacing-16;
  order: 2;
  width: 100%;

  @include media(pc, tl) {
    order: 3;
  }
}

.furusatoNoseiSection {
  padding: $spacing-16 0;
  // margin: $spacing-16 $spacing-16 0 $spacing-16;
  margin-top: $spacing-16;
  border-top: 1px dashed $pastel-gray;

  @include media(pc, tl) {
    display: flex;
    padding: $spacing-16 0 0;
  }

  &.omitBottomSpacing {
    padding-bottom: 0;
  }
}

.furusatoNoseiSection + .rakutenMobileSection {
  &:not(.omitBottomSpacing) {
    @include media(sp) {
      margin-top: 0;
    }
  }

  &.omitBottomSpacing {
    @include media(sp) {
      margin-top: $spacing-16;
    }
  }
}

.rakutenMobileSection {
  padding: $spacing-16 0;
  margin-top: $spacing-16;
  border-top: 1px dashed $pastel-gray;

  @include media(pc, tl) {
    display: flex;
    padding: $spacing-16 0 0;
  }

  &.omitBottomSpacing {
    padding-bottom: 0;
  }
}

// tl, pc only
.reservationMedia {
  margin: $spacing-16;
  margin-right: $spacing-16;
  width: 152px;

  .media {
    width: 100%;
    border-radius: 4px;
    background: transparent;
  }
}
