@import '~@travel-ui/styles/src/utility';
@import '../../../../style/app.module.scss';

%component-height {
  height: 112px;

  @include media(sp) {
    height: 72px;
  }
}

.wrapper {
  @extend %component-height;
}

.backgroundContainer {
  @extend %component-height;
  position: absolute;
  left: 0;
  width: 100%;
}

.contentContainer {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  @include media(tl, sp) {
    padding-left: $spacing-16;
  }

  @include media(pc) {
    @include pc-layout;
  }
}

.title {
  @include font-sp-header-h4-bold;
  width: 100%;

  @include media(pc) {
    @include font-pc-header-h1-bold;
  }
}

.image {
  width: auto;
  height: 100%;
}
