@import '~@travel-ui/styles/src/utility';

$popup-pointer-size: 15px;
$triangle-size: 7.4px;

.popup {
  position: absolute;
  border-radius: $border-radius;
  border: 1px solid $pastel-gray;
  background-color: $white;
  padding: $spacing-m;
  margin-top: $spacing-s;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  z-index: $zIndex-dropdown;

  .triangle {
    position: absolute;
    top: -#{$popup-pointer-size};
    left: 75%;
    width: 0;
    height: 0;
    border-top: $triangle-size solid transparent;
    border-bottom: $triangle-size solid $pastel-gray;
    border-right: $triangle-size solid transparent;
    border-left: $triangle-size solid transparent;

    &.middle {
      left: 50%;
    }

    &.right {
      left: 7%;
    }

    &.extremeLeft {
      left: 87%;
    }
  }
}
