@import '~@travel-ui/styles/src/utility';

.tab {
  @include resetButtonStyle;
  @include font-label;

  color: $jet;
  height: 44px;

  @include media(pc) {
    margin-right: $spacing-base;
  }
}

.selectedTab {
  color: $cilantro;
  border-bottom: 2px solid $cilantro;
}

.buttonWrapper {
  padding: 0 $spacing-base;
  display: inline-block;

  &:first-child {
    padding-left: 0;
  }
}

.tabpanel {
  position: relative;
  display: none;
  outline: 0;
}

.showPanel {
  display: block;
}

.panelContainer {
  display: flex;
  overflow: hidden;
}

.swipeItem {
  flex-shrink: 0;
  position: relative;
}

.placeholder {
  display: block;
  background: $white;
  width: 100%;
  padding: $spacing-m;

  .skeletonItem:not(:last-child) {
    margin-bottom: $spacing-base;
  }
}
