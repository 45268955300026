@import '~@travel-ui/styles/src/utility';

@mixin journeyInfoFont {
  @include font-sp-captions-c2;

  @include media(pc) {
    @include font-pc-captions-c1;
  }
}

.container {
  @include journeyInfoFont();
  display: flex;

  @include media(tl, pc) {
    margin-top: $spacing-04;
  }
}

.header {
  @include journeyInfoFont();
  color: $sonic-silver;
  width: 20%;

  @include media(pc, tl) {
    width: 15%;
    margin-right: $spacing-08;
  }
}
