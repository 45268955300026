@import '~@travel-ui/styles/src/utility';

.wrapper {
  padding: $spacing-16;

  @include media(sp) {
    overflow: hidden;
  }

  @include media(pc) {
    padding: $spacing-32 0 $spacing-16 0;
  }
}

.nextReservations {
  width: 100%;
  max-width: 100%;
}

.listWrapper {
  margin-top: $spacing-08;
}

.emptyResultSearchContainer {
  padding-bottom: $spacing-24;
}
