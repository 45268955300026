@import '~@travel-ui/styles/src/utility';

$arrow-icon-size: 16px;
$arrow-icon-spacing--sp: $spacing-12;
$arrow-icon-spacing--pc: $spacing-32;

.container {
  margin-bottom: $spacing-16;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;

  @include media(pc, tl) {
    justify-content: unset;
  }
}

.hotelContainer {
  flex-direction: column;
}

.hotelDateContainer {
  display: flex;
}

.specialNoteContainer {
  @include font-pc-captions-c2;
  color: $sonic-silver;
}

.alignBottom {
  align-items: flex-end;
}

.legContainerWidth {
  width: calc(50% - calc(#{$arrow-icon-size} / 2) - #{$arrow-icon-spacing--sp});
  flex-shrink: 0;

  @include media(tl, pc) {
    width: calc(50% - calc(#{$arrow-icon-size} / 2) - #{$arrow-icon-spacing--pc});
  }
}

.separator {
  @include font-sp-captions-c2;
  color: $sonic-silver;
  display: flex;
  margin-top: $spacing-08;
  margin-bottom: $spacing-08;
  justify-content: center;

  @include media(pc) {
    margin-top: $spacing-16;
    margin-bottom: $spacing-16;
  }
}

.column {
  width: 50%;
  display: inline-flex;
  justify-content: space-between;

  &:first {
    margin-right: $spacing-04;
  }

  @include media(pc, tl) {
    justify-content: unset;
    display: flex;
  }
}

.bottomPush {
  margin-bottom: $spacing-08;

  @include media(pc) {
    margin-bottom: $spacing-16;
  }
}

.packageDivider {
  border-bottom: 1px dashed $pastel-gray;
  margin-top: $spacing-16;
  margin-bottom: $spacing-16;
}

.icon {
  flex-shrink: 0;
  margin-right: $arrow-icon-spacing--sp;
  margin-left: $arrow-icon-spacing--sp;
  align-self: center;

  @include media(tl, pc) {
    margin-right: $arrow-icon-spacing--pc;
    margin-left: $arrow-icon-spacing--pc;
  }

  &.bottomPosition {
    transform: translateY(100%);
  }

  &.jrBottomPosition {
    transform: translateY(175%);
  }
}

.departurePlace {
  @include font-sp-body-b2;
  display: inline-block;
  margin-top: $spacing-04;

  @include media(pc, tl) {
    @include font-pc-body-b2;
  }

  &.departurePlaceWithTrainName {
    margin-top: 0px;
  }
}

.withTrainName {
  margin-top: $spacing-24;
}

.trainName {
  @include font-sp-body-b2;
  margin-top: $spacing-04;

  @include media(pc, tl) {
    @include font-pc-body-b2;
  }
}

.sameDate {
  white-space: nowrap;
}

.legMediaWrapper {
  line-height: 0;
}

.legMedia {
  max-height: 20px;
  flex-shrink: 0;
  background: transparent;
}

.flightTitleBox {
  @include font-sp-captions-c2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid $isabelline;
  padding-bottom: $spacing-04;
  margin-bottom: $spacing-04;
  width: 100%;

  .flightTitle {
    display: flex;
    align-items: flex-end;
  }
}

.flightName {
  @include font-sp-captions-c2;
  display: inline-block;
  margin-right: $spacing-04;
}

.subText {
  color: $sonic-silver;
  padding-left: $spacing-04;
}

.header {
  @include font-sp-captions-c2-bold;
  color: $sonic-silver;
}

.busDepartureStation {
  color: $dark-cyan;
  text-decoration-line: underline;

  &:hover {
    color: $dark-sky-blue;
  }
}

.overseaDpDivider {
  padding-bottom: $spacing-08;
  border-bottom: 1px dashed $pastel-gray;
  margin-bottom: $spacing-16;

  @include media(pc) {
    padding-bottom: $spacing-24;
  }
}
