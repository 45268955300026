@import '~@travel-ui/styles/src/utility';

.container {
  display: inline-flex;
  border-radius: 12px;
  padding: 0 $spacing-base;
  align-items: center;
}

.default {
  border: 1px solid $isabelline;
}

.green {
  background-color: $laurel-green;
  color: $lincoln-green;

  &.border {
    border: 1px solid $cilantro;
  }
}

.yellow {
  background-color: $mellow-yellow;
  color: $chocolate;

  &.border {
    border: 1px solid $sunflower;
  }
}

.red {
  background-color: $ruddy-pink;
  color: $scarlet;

  &.border {
    border: 1px solid $apple-red;
  }
}

.empty {
  width: 72px;
  height: 18px;
}
