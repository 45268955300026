@import '~@travel-ui/styles/src/utility';

.dialogContent {
  background-color: $white;
  height: 100%;
  @include media(pc) {
    &#{&} {
      width: 450px;
      height: 400px;
    }
  }
}

.dialogContainer {
  &#{&} {
    display: flex;
    flex-direction: column;

    // to override the max height as we implemented a custom header/action part
    > div:first-child {
      max-height: unset;
    }
  }
}

.reservationId {
  @include font-sp-header-h3;
  margin-top: $spacing-16;
}

.qrCode {
  width: 160px;
  height: 160px;
}

.coupon {
  background-color: $white;
  border-bottom: 1px solid $isabelline;
}

.actionDialog {
  &#{&} {
    @include shadow-1;
    flex-direction: column;
    padding: $spacing-12 $spacing-16 $spacing-16;
  }
}

.priceContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.currentPriceContainer {
  @include font-pc-captions-c2;
}

.lineThrough {
  text-decoration: line-through;
  margin-left: $spacing-04;
}

.discountedPriceContainer {
  @include font-sp-body-b2;
  color: $scarlet;
}

.bold {
  font-weight: bold;
}

.actionLabelContainer {
  width: 100%;
}

.button {
  &#{&} {
    max-width: 100%;
    min-height: 36px;
    margin-top: $spacing-08;
  }
}

.actionPrice {
  @include font-pc-body-b1;
  margin-left: $spacing-04;
}

.loadingWrapper {
  padding: $spacing-16;

  .loadingItem {
    margin-bottom: $spacing-08;
  }
}
