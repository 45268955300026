@import '~@travel-ui/styles/src/utility';

$content-max-width: 360px;

.wrapper {
  width: 80vw;
  margin: auto;
  padding-top: $spacing-32;
  padding-bottom: $spacing-32;
  text-align: center;

  @include media(tl, pc) {
    width: 100%;
    background: $shady-white;
    padding-bottom: $spacing-32;
  }

  @include media(pc) {
    &::after {
      content: ' ';
      height: 16px;
      width: 100%;
      display: block;
      border-bottom: 1px solid $isabelline;
      background: $white;
      position: relative;
      top: $spacing-32;
    }
  }
}

.icon {
  width: 100%;
  margin-bottom: $spacing-24;

  @include media(pc) {
    height: 250px;
  }
}

.emptyText {
  @include font-sp-body-b2;
  text-align: center;
  max-width: $content-max-width;
  margin: 0 auto;

  @include media(pc) {
    @include font-pc-body-b2;
  }
}

.resetButton {
  margin-top: $spacing-08;
  width: 100%;
  padding: $spacing-08;
  background: $white;
  border-radius: 4px;
  border: 1px solid $pastel-gray;
  max-width: $content-max-width;

  @include media(pc) {
    margin-top: $spacing-16;
  }
}
