@import '~@travel-ui/styles/src/utility';

.wrapper {
  display: inline-block;
  position: relative;
}

.popup {
  padding: 0;
  width: 100%;
}

.displayBox {
  cursor: pointer;
  width: 100%;
}

.displayBoxContent {
  display: flex;
  align-items: center;
}

.optionItem {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchBox {
  border-bottom: 1px solid $isabelline;
}

.listContainer {
  height: 100%;
}

.searchInput {
  width: 100%;
  outline: none;
  border: none;
}

.closeButton{
  cursor: pointer;
}