/********
* This file is being used for overriding the inner 3rd party element which could not be access by common className only
* Please use media.module.scss as default
*****/

.brightcover-react-player,
.brightcover-react-player .video-js {
  width: 100%;
  height: 100%;
}
