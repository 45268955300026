@import '~@travel-ui/styles/src/utility';

.media {
  background: $shady-white;
}

.empty {
  align-items: center;
  background: $shady-white;
  border: $isabelline;
  box-sizing: border-box;
  display: flex;
  @include border-radius-all($border-radius-4px);

  .icon {
    margin: 0 auto;
  }
}

.disabled {
  pointer-events: none;
}
